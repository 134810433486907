<template>
  <div class='main'
       ref="main">
    <div class="topbar">
      <div class="topbar-left">
        <Button @click.stop="onClickCreateFolder"
                class="newFolder">
          <Icon custom="i-icon icon-add"
                size="16"/>
          新建文件夹
        </Button>
        <ButtonGroup class="m-l-30"
                     v-show="selectArr.length>0">
          <Button @click.stop="batchShare"
                  class="batchBtn"
                  style="display: none">
            <Icon custom="i-icon icon-share"
                  size="16"/>
            分享
          </Button>
          <Button class="batchBtn" style="display: none">
            <Icon custom="i-icon icon-download"
                  size="16"/>
            下载
          </Button>
          <Button @click.stop="batchDeleteFolderAndFile"
                  class="batchBtn">
            <Icon custom="i-icon icon-delete"
                  size="16"/>
            删除
          </Button>
          <Button @click.stop="onClickRename"
                  class="batchBtn"
                  v-show="selectArr.length===1">
            <Icon custom="i-icon icon-rename"
                  size="16"/>
            重命名
          </Button>
          <Button @click.stop="batchMove"
                  class="batchBtn">
            <Icon custom="i-icon icon-remove"
                  size="16"/>
            移动到
          </Button>
          <Button @click.stop="batchAddTag"
                  class="batchBtn">
            <Icon custom="i-icon icon-tags"
                  size="16"/>
            添加标签
          </Button>
          <Button @click="onPermissionSet" class="batchBtn" v-if=" showPermssion">
            <Icon custom="i-icon icon-permission"
                  size="16"/>
            权限
          </Button>
          <!-- <Dropdown trigger="click"
                    @on-click="onClickBatchTag">
            <Button class="batchBtn"
                    style="border-top-left-radius: 0;border-bottom-left-radius: 0;margin-left:-1px">
              <Icon type="md-pricetag"
                    size="20" />
              标签
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="look"
                            :disabled="selectArr.length>1">查看标签</DropdownItem>
              <DropdownItem name="add">添加标签</DropdownItem>
              <DropdownItem name="delete"
                            :disabled="selectArr.length>1">删除标签</DropdownItem>
            </DropdownMenu>
          </Dropdown> -->
        </ButtonGroup>
      </div>
      <div class="topbar-right">
        <div class="search">
          <Input :border="false"
                 @on-search="onNameSearch"
                 class="search-style"
                 placeholder="搜索文件夹"
                 search
                 v-model="search"/>
          <!--<Icon custom="i-icon icon-search"-->
          <!--size="14"/>-->
        </div>
        <div class="listType">
          <Icon :color="listShowType===1?'#2D8CF0':'#666'"
                @click="listShowType = 1"
                custom="i-icon icon-list1x"
                size="20"/>
          <Icon :color="listShowType===2?'#2D8CF0':'#666'"
                @click="listShowType = 2"
                custom="i-icon icon-view1x"
                size="20"/>
        </div>
      </div>
    </div>
    <Breadcrumb :data="pathArr"
                @onClickItem="onClickBreadcrumbItem"
                class="p-20 p-r-50"></Breadcrumb>
    <div class="list">
      <div v-show="listShowType === 1">
        <DiskTableComponent :columns="columns"
                            :data="data"
                            :sortKey="sortKey"
                            :sortType="sortType"
                            @on-click-addTag="onClickAddTag"
                            @on-click-deleteTag="onClickDeleteTag"
                            @on-click-lookTag="onClickLookTag"
                            @on-create-folder="createFolder"
                            @on-rename="onRename"
                            @on-row-dblclick="onRowDblclick"
                            @on-selection-change="onSelectionChange"
                            @on-sort-change="onSortChange"
                            ref="table">
          <template slot="operation"
                    slot-scope="{ row,index }">
            <div class="operation">
              <!-- <Icon custom="i-icon icon-share"
                    size="20"
                    color="#2D8CF0"
                    class="m-r-10"
                    title="分享" />
              <Icon custom="i-icon icon-download"
                    size="20"
                    color="#2D8CF0"
                    class="m-r-10"
                    title="下载" /> -->
              <Dropdown @on-click="(key)=>{onClickOperation(key,row,index)}"
                        transfer
                        trigger="click">
                <Icon color="#2D8CF0"
                      custom="i-icon icon-more"
                      size="20"
                      title="操作"/>
                <DropdownMenu slot="list">
                  <DropdownItem name="share" style="display: none">分享</DropdownItem>
                  <DropdownItem name="download" style="display: none">下载</DropdownItem>
                  <DropdownItem name="move">移动到</DropdownItem>
                  <DropdownItem name="rename">重命名</DropdownItem>
                  <DropdownItem name="delete">删除</DropdownItem>
                  <DropdownItem name="authority" style="display: none">权限管理</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </template>
        </DiskTableComponent>
      </div>
      <div v-show="listShowType === 2">
        <DiskGridComponent :data="data"
                           :grids="grids"
                           :sortKey="sortKey"
                           :sortType="sortType"
                           @on-click-addTag="onClickAddTag"
                           @on-click-deleteTag="onClickDeleteTag"
                           @on-click-lookTag="onClickLookTag"
                           @on-create-folder="createFolder"
                           @on-rename="onRename"
                           @on-row-dblclick="onRowDblclick"
                           @on-selection-change="onSelectionChange"
                           @on-sort-change="onSortChange"
                           ref="grid"></DiskGridComponent>
      </div>
      <infinite-loading :distance="200"
                        :identifier="manualResetState"
                        @infinite="getAllFileList"
                        ref="infiniteLoading">
        <div class="total"
             slot="no-more">共{{data.length}}项
        </div>
      </infinite-loading>
    </div>
    <DiskDirectoryComponent :selectArr="selectArr"
                            :title="directoryTitle"
                            @on-click-cancle="cancleSelectFolder"
                            @on-click-confirm="getSelectFolder"
                            v-model="directoryVisible"></DiskDirectoryComponent>
    <TagAddComponent :width="width"
                     @on-click-cancle="cancleSelectAddTag"
                     @on-click-confirm="getSelectAddTag"
                     v-model="addTagVisible"></TagAddComponent>
    <TagLookComponent :data="tagArr"
                      v-model="lookTagVisible"></TagLookComponent>
    <TagDeleteComponent :data="tagArr"
                        @on-click-cancle="cancleSelectDeleteTag"
                        @on-click-confirm="getSelectDeleteTag"
                        v-model="deleteTagVisible"></TagDeleteComponent>
    <!--<DiskShareComponent :data="shareArr"-->
    <!--:obj="shareObj"-->
    <!--v-model="shareVisible"></DiskShareComponent>-->
    <Modal @on-visible-change="onModalClose"
           class="modal"
           class-name="vertical-center-modal"
           footer-hide
           v-model="showFolder"
           width="600">
      <div>
        <div class="b-b h-50 p-h-30 lh-50">
          <span>{{'文件夹权限'}}</span>
        </div>
        <div class="p-h-50 m-t-20">
          <Input placeholder="输入文本" readonly v-model="inputText"></Input>
        </div>
        <div class="p-h-50 m-t-15">
          <div class="fs-12">
            文件夹权限设置
          </div>
          <div class="fs-14 m-t-10">
            <RadioGroup v-model="chooseType">
              <Radio :label="1" class="m-r-15">公开</Radio>
              <Radio :label="3" class="m-r-15">私密</Radio>
              <Radio :label="2">部分公开</Radio>
            </RadioGroup>
          </div>
        </div>
        <div class="p-h-50 m-t-15" v-if="chooseType === 2">
          全选/全不选 <input type="checkbox" v-model="select">
          <ul class="flex a-center flex-wrap p-10" style="background:#F8F8F9">
            <li :key="index" class="w-p-25 flex-s-0 o-hidden" style="white-space: nowrap"
                v-for="(item,index) in userList">
              <input type="checkbox" v-model="item.flag">
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="flex a-center j-center p-v-20">
          <Button @click="onFloderSaveClick">确定</Button>
          <Button @click="showFolder=false" class="m-l-20">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  cancleRelevanceTag,
  createFolder,
  createShareUrl,
  deleteFolderAndFileToRecycle,
  editFileName,
  editFolderName,
  getFileList,
  getFolderAndFileTag,
  getFolderList,
  moveFolderAndFile,
  relevanceTag
} from '../../services/diskapi'
import DiskUploadComponent from '../../components/disk/DiskUploadComponent'
import DiskTableComponent from '../../components/disk/DiskTableComponent'
import DiskGridComponent from '../../components/disk/DiskGridComponent'
import Breadcrumb from '../../components/common/Breadcrumb'
import DiskDirectoryComponent from '../../components/disk/DiskDirectoryComponent'
import TagAddComponent from '../../components/disk/TagAddComponent'
import TagLookComponent from '../../components/disk/TagLookComponent'
import TagDeleteComponent from '../../components/disk/TagDeleteComponent'
import DiskShareComponent from '../../components/disk/DiskShareComponent'
import bus from '../../utils/bus'
import {mapActions} from 'vuex'

export default {
  components: {
    DiskUploadComponent,
    DiskTableComponent,
    DiskGridComponent,
    Breadcrumb,
    DiskDirectoryComponent,
    TagAddComponent,
    TagLookComponent,
    TagDeleteComponent,
    DiskShareComponent
  },
  data () {
    return {
      inputText: '',
      showFolder: false,
      chooseType: 1,
      selEditFloderId: 0,
      userList: [],
      myId: 0,
      folderId: 0,
      folderName: '全部',
      parentId: 0,
      search: '',
      listShowType: 1,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '文件名称',
          key: 'name',
          minWidth: 200,
          // sortable: 'custom',
          tooltip: true,
          slot: 'name'
        },
        {
          title: '创建时间',
          key: 'editTime',
          width: 160,
          // sortable: 'custom',
        },
        {
          title: '文件大小',
          key: 'size',
          width: 140,
          // sortable: 'custom',
          render: (h, params) => {
            let size = params.row.size
            let text = size ? this.$formatFileSize(size) : '-'
            return h('span', text)
          }
        },
        {
          title: '创建人',
          key: 'userName',
          width: 140,
        },
        {
          title: '权限',
          key: 'persiton',
          width: 120,
        },
        {
          title: '标签',
          key: '',
          slot: 'tag',
          width: 120
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140
        }
      ],
      grids: [{
        title: '文件名称',
        key: 'name'
      },
        {
          title: '创建时间',
          key: 'editTime',
        },
        {
          title: '文件大小',
          key: 'size',
        }],
      data: [],
      selectArr: [],
      pageNum: 1,
      pageSize: 20,
      isMoreFolder: true,
      isMoreFile: true,
      sortKey: 'name',
      sortType: 'asc',
      manualResetState: false,//手动重置触底加载状态
      directoryVisible: false,
      directoryTitle: '移动',
      addTagVisible: false,
      width: 0,
      lookTagVisible: false,
      tagArr: [],
      deleteTagVisible: false,
      shareVisible: false,
      shareArr: [],
      shareObj: null
    }
  },
  watch: {
    '$route.params.code': {
      handler (newValue) {
        this.search = ''
        this.init()
      },
      immediate: true
    }
  },
  computed: {
    pathArr () {
      return this.$store.state.disk.pathArr
    },
    select: {
      // 显示修改后的数据
      get () {
        return this.userList.every((item) => item.flag)
      },
      // 修改数据  传参
      set (v) {
        this.userList.forEach((item) => item.flag = v)
      },
    },
    showPermssion () {
      console.log('this.selectArr:===>', this.selectArr)
      if (this.selectArr && this.selectArr.length === 1) {
        let item = this.selectArr[0]
        console.log('item:===>', item)
        if (item.objType === 2 && item.parentId === 0) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
    this.width = this.$refs.main.offsetWidth
    bus.$on('uploadSuccess', this.resetListData)
  },
  methods: {
    init () {
      this.myId = this.$store.state.user.userId
      if (this.$route.params.code) {
        let params = JSON.parse(this.$Base64.decode(this.$route.params.code))
        this.folderId = params.id
        this.folderName = params.name
        this.parentId = params.parentId
        this.permissionType = params.permissionType || 1
      } else {
        this.folderId = 0
        this.folderName = '全部'
        this.parentId = 0
        this.permissionType = 1
      }
      this.resetListData()
      this.setPathArr({
        id: this.folderId,
        name: this.folderName,
        parentId: this.parentId
      })
      this.getEmployeeLists()
    },
    getEmployeeLists () {
      let params = {
        pageNum: 1,
        pageSize: 1000
      }
      this.getEmployeeList(params).then(res => {
        if (res.code === 0) {
          this.userList = res.data.list.filter(child => {
            return child.id !== this.myId
          }).map(child => {
              child.role = this.getRole(child.roleType)
              child.flag = false
              return child
            }
          )
        }
      })
    },
    getRole (type) {
      let roleName = ''
      switch (type) {
        case 1:
          roleName = '超级管理员'
          break
        case 2:
          roleName = '管理员'
          break
        case 3:
          roleName = '普通用户'
          break
      }
      return roleName
    },
    async onPermissionSet () {
      if (this.selectArr && this.selectArr.length) {
        let list = this.selectArr
        if (list && list.length) {
          let id = list[0].id
          let result = await this.getFolderDetail(id)
          if (result.code === 0) {
            let data = result.data
            this.inputText = data.name
            this.chooseType = data.permissionType || 1
            let persons = data.userList.map(res => {
              return res.id
            })
            this.userList.map(res => {
              if (persons.indexOf(res.id) !== -1) {
                res.flag = true
              } else {
                res.flag = false
              }
            })
            this.selEditFloderId = id
            this.showFolder = true
          }
        }
      }
    },
    onFloderSaveClick () {
      let userList = this.userList.filter(res => {
        return res.flag
      }).map(res => {
        return {
          id: res.id
        }
      })
      if (userList && userList.length) {
        let user = new Set()
        userList.map(child => {
          user.add(child.id)
        })
        if (!user.has(this.myId)) {
          userList.push({
            id: this.myId
          })
        }
      } else {
        userList.push({
          id: this.myId
        })
      }
      let id = this.selectArr[0].id
      let params = {
        folderGroupId: 1,
        permissionType: this.chooseType || 1,
        name: this.inputText || '',
        userList: userList || []
      }
      editFolderName(id, params).then(res => {
        if (res.code === 0) {
          this.resetListData()
          this.showFolder = false
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })

    },
    // 点击面包屑
    onClickBreadcrumbItem (item) {
      if (item.id === 0) {
        this.$router.push({
          path: '/folder/'
        })
      } else {
        let encode = this.$Base64.encode(JSON.stringify(item))
        this.$router.push({
          path: '/folder/' + encode
        })
      }
      this.setPathArr({
        id: item.id,
        name: item.name
      })
    },
    // 重置列表数据
    resetListData () {
      this.pageNum = 1
      this.data = []
      this.selectArr = []
      this.isMoreFolder = true
      this.isMoreFile = true
      this.manualResetState = !this.manualResetState
    },
    onNameSearch () {
      this.pageNum = 1
      this.resetListData()
    },
    // 排序
    onSortChange (obj) {
      this.sortKey = obj.key
      this.sortType = obj.order
      this.resetListData()
    },
    // 获取列表
    getAllFileList ($state) {
      if (this.isMoreFolder) {
        this.getFolderLists($state)
        // } else if (this.isMoreFile) {
        //   this.getFileList($state)
      } else {
        $state.complete()
      }
    },
    // 获取文件夹列表
    getFolderLists ($state) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentId: this.folderId === 0 ? -1 : this.folderId,
        folderType: 1,
        sort: this.sortKey === 'editTime' ? 2 : 1,
        mode: this.sortType === 'asc' ? 1 : 2
      }
      if (this.search) {
        params.name = this.search.replace(/[\[\]\^%\<>\\|*?,{}/]/g, '')
      }
      getFolderList(params).then(res => {
        if (res.code === 0) {
          let list = res.data.list
          if (list.length === 0) {
            this.isMoreFolder = false
            this.pageNum = 1
            $state.loaded()
            return
          }
          list.forEach(item => {
            item.objType = 2
            item.newName = item.name || ''
            item.editTime = item.uploadTime || ''
            item.isRename = false
            item.isCheck = false
            item.isSign = item.isHasLabel
            let uploadUser = item.uploadUser
            item.userName = uploadUser ? uploadUser.name || '' : ''
            let permissionType = '-'
            // if (item.parentId === 0) {
            switch (item.permissionType) {
              case 1:
                permissionType = '公开'
                break
              case 3:
                permissionType = '私密'
                break
              case 2:
                permissionType = '部分公开'
                break
            }
            // }
            item.persiton = permissionType
          })
          if (this.pageNum === 1) {
            this.$refs.table.resetSelectArr()
            this.$refs.grid.resetSelectArr()
          }
          this.data = this.data.concat(list)
          if (res.data.pages === params.pageNum) {
            this.isMoreFolder = false
            this.pageNum = 1
          } else {
            this.pageNum++
          }
          $state.loaded()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取文件列表
    getFileList ($state) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        folderId: this.folderId === 0 ? -1 : this.folderId,
        folderType: 1,
        sort: this.sortKey === 'name' ? 1 : this.sortKey === 'editTime' ? 2 : 3,
        mode: this.sortType === 'asc' ? 1 : 2
      }
      if (this.search) params.name = this.search
      getFileList(params).then(res => {
        if (res.code === 0) {
          let list = res.data.list
          if (list.length === 0) {
            this.isMoreFile = false
            $state.complete()
            return
          }
          list.forEach(item => {
            let content = item.content
            item.name = content.displayName || content.name || ''
            item.editTime = item.uploadTime || ''
            item.size = content.size || 0
            item.objType = 1
            item.newName = content.displayName || content.name || ''
            item.url = content.url || ''
            item.isRename = false
            item.isCheck = false
            let uploadUser = item.uploadUser
            item.userName = uploadUser ? uploadUser.name || '' : ''
          })
          this.data = this.data.concat(list)
          if (res.data.pages === params.pageNum) {
            this.isMoreFile = false
            $state.complete()
          } else {
            this.pageNum++
          }
          $state.loaded()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 选择改变
    onSelectionChange (selection) {
      this.selectArr = selection
    },
    // 双击某一行
    onRowDblclick (row) {
      if (row.isRename) {
        return
      }
      if (row.objType === 1) {
        let url = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(row.url))
        window.open(url)
      } else if (row.objType === 2) {
        let params = {
          id: row.id,
          name: row.name,
          parentId: row.parentId,
          permissionType: row.permissionType || 1
        }
        let encode = this.$Base64.encode(JSON.stringify(params))
        this.$router.push({
          path: '/folder/' + encode
        })
      }
    },
    // 操作
    onClickOperation (key, row, index) {
      let item = this.data[index]
      switch (key) {
        case 'share':
          this.onClickShare(row)
          break
        case 'download':

          break
        case 'move':
          this.onClickMove(row)
          break
        case 'rename':
          this.$refs.table.onClickRename(item, index)
          break
        case 'delete':
          this.onClickDelete(row)
          break
        case 'authority':
          break
      }
    },
    // 重命名
    onRename (item) {
      if (item.objType === 1) {
        let content = item.content
        content.displayName = item.newName || ''

        let params = {
          folderGroupId: item.folderGroupId,
          folderId: item.folderId,
          content: content
        }
        editFileName(item.id, params).then(res => {
          item.isRename = false
          if (res.code === 0) {
            item.name = item.newName
          } else {
            item.newName = item.name
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        let permissionType = 1
        let userList = []
        if (item.folder) {
          permissionType = item.folder.permissionType || 1
          userList = item.folder.userList
        }
        let params = {
          folderGroupId: 1,
          permissionType: permissionType,
          name: item.newName,
          userList: userList
        }
        editFolderName(item.id, params).then(res => {
          item.isRename = false
          if (res.code === 0) {
            item.name = item.newName
          } else {
            item.newName = item.name
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击删除
    onClickDelete (row) {
      this.onDelete([row])
    },
    // 点击批量删除
    batchDeleteFolderAndFile () {
      this.onDelete(this.selectArr)
    },
    // 批量删除文件夹与文件进回收站
    onDelete (list) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>删除后将移入回收站</p>',
        onOk: () => {
          let folderIdList = []
          let idList = []
          list.forEach(item => {
            if (item.objType === 1) {
              idList.push(item.id)
            } else {
              folderIdList.push(item.id)
            }
          })
          let olderIds = folderIdList.join(',')
          let ids = idList.join(',')
          deleteFolderAndFileToRecycle({
            folderIdList: olderIds,
            idList: ids
          }).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.resetListData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 获取选择的文件夹
    getSelectFolder (obj) {
      this.$emit('getMoveSelectFolder', obj)
    },
    // 取消选择文件夹
    cancleSelectFolder () {
      this.$off('getMoveSelectFolder')
    },
    // 点击移动
    onClickMove (row) {
      this.directoryVisible = true
      this.$once('getMoveSelectFolder', (query) => {
        this.onMove(query, [row])
      })
    },
    // 点击批量移动
    batchMove () {
      this.directoryVisible = true
      this.$once('getMoveSelectFolder', (query) => {
        this.onMove(query, this.selectArr)
      })
    },
    // 批量移动文件夹与文件
    onMove (query, arr) {
      let folderList = []
      let documentList = []
      arr.forEach(item => {
        if (item.objType === 1) {
          documentList.push({
            id: item.id
          })
        } else {
          folderList.push({
            id: item.id
          })
        }
      })
      let params = {
        folderType: 1,
        folderId: query.folderId,
        folderList,
        documentList
      }
      moveFolderAndFile(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('移动成功!')
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击重命名
    onClickRename () {
      let selectItem = this.selectArr[0]
      let item
      let index
      let objType = selectItem.objType
      for (const i in this.data) {
        let dataItem = this.data[i]
        if (objType === 1) {
          if (selectItem.id === dataItem.id) {
            item = dataItem
            index = i
            break
          }
        } else {
          if (selectItem.id === dataItem.id) {
            item = dataItem
            index = i
            break
          }
        }
      }
      let comp = this.listShowType === 1 ? 'table' : 'grid'
      this.$refs[comp].onClickRename(item, index)
    },
    // 点击新建文件夹button
    onClickCreateFolder () {
      let item = {
        objType: 2,
        folderId: 0,
        name: '',
        newName: '新建文件夹',
        isRename: true
      }
      this.data.unshift(item)
      this.onClickOperation('rename', item, 0)
    },
    // 新建文件夹
    createFolder (item) {
      let params = {
        // folderType: 1,
        // folderId: this.folderId,
        // folderList: [{folderName: item.newName}]
        desc: '',
        folderGroupId: 1,
        folderType: '1',
        name: item.newName,
        parentId: this.folderId,
        permissionType: 1,
        userList: [{}]
      }
      createFolder(params).then(res => {
        if (res.code === 0) {
          try {
            item = res.data[0]
            item.objType = 2
            item.newName = item.name
            item.isRename = false
            item.isCheck = false
            this.data.unshift(item)
          } catch (e) {

          }
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取选择的添加标签
    getSelectAddTag (obj) {
      this.$emit('getSelectAddTagArr', obj)
    },
    // 取消选择添加标签
    cancleSelectAddTag () {
      this.$off('getSelectAddTagArr')
    },
    // 点击添加标签
    onClickAddTag (row) {
      this.addTagVisible = true
      this.$once('getSelectAddTagArr', (query) => {
        this.relevanceTag(query, [row])
      })
    },
    // 点击批量添加标签
    batchAddTag () {
      this.addTagVisible = true
      this.$once('getSelectAddTagArr', (query) => {
        this.relevanceTag(query, this.selectArr)
      })
    },
    // 关联标签
    relevanceTag (query, arr) {
      let relationList = []
      arr.forEach(item => {
        relationList.push({
          relationType: item.objType,
          folderType: 1,
          relationId: item.id
        })
      })
      let params = {
        relationList,
        labelList: query.map(item => {
          return {
            id: item
          }
        })
      }
      relevanceTag(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('添加标签成功!')
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击查看标签
    onClickLookTag (row) {
      let params = {
        relationId: row.id,
        type: row.objType
      }
      getFolderAndFileTag(params).then(res => {
        if (res.code === 0) {
          this.tagArr = res.data.map(child => {
            child.labelId = child.id
            child.labelColor = child.color
            child.labelName = child.name
            return child
          })
          this.lookTagVisible = true
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取选择的删除标签
    getSelectDeleteTag (obj) {
      this.$emit('getSelectDeleteTagArr', obj)
    },
    // 取消选择删除标签
    cancleSelectDeleteTag () {
      this.$off('getSelectDeleteTagArr')
    },
    // 点击删除标签
    onClickDeleteTag (row) {
      let params = {
        relationId: row.id,
        type: row.objType
      }
      getFolderAndFileTag(params).then(res => {
        if (res.code === 0) {
          this.tagArr = res.data.map(child => {
            child.labelId = child.id
            child.labelColor = child.color
            child.labelName = child.name
            return child
          })
          this.deleteTagVisible = true
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
      this.$once('getSelectDeleteTagArr', (query) => {
        this.cancleRelevanceTag(query, row)
      })
    },
    // 取消关联标签
    cancleRelevanceTag (query, row) {
      let params = {
        labelIdList: query.join()
      }
      if (row.objType === 1) {
        params.documentIdList = row.id
        params.folderIdList = ''
      } else {
        params.documentIdList = ''
        params.folderIdList = row.id
      }
      cancleRelevanceTag(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('删除标签成功!')
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击分享
    onClickShare (row) {
      this.shareArr = [row]
      this.createShareUrl()
    },
    // 批量分享
    batchShare () {
      this.shareArr = this.selectArr
      this.createShareUrl()
    },
    // 创建分享链接
    createShareUrl () {
      let objList = [
        {
          objType: 1,
          objList: []
        }, {
          objType: 2,
          objList: []
        }
      ]
      this.shareArr.forEach(item => {
        if (item.objType === 1) {
          objList[0].objList.push({
            objId: item.fileId,
            objName: item.name
          })
        } else {
          objList[1].objList.push({
            objId: item.folderId,
            objName: item.name
          })
        }
      })
      let params = {
        shareType: 1,
        endDay: 30,
        objList
      }
      createShareUrl(params).then(res => {
        if (res.code === 0) {
          this.shareObj = res.data
          this.shareVisible = true
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    onModalClose (status) {
      if (!status) {
        this.inputText = ''
        this.chooseType = 1
        this.selEditFloderId = 0
        this.userList.map(res => {
          res.flag = false
        })
      }
    },
    ...mapActions([
      'setPathArr',
      'getFolderDetail',
      'getEmployeeList',
    ]),
  }
}

</script>
<style lang='scss' scoped>
  .main {
    width: 100%;
    height: 100%;
    padding-left: 30px;

    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 50px 0 20px;

      .topbar-left {
        display: flex;
        align-items: center;

        .upload {
          position: relative;

          .upload-btn {
            width: 98px;
            height: 34px;
          }

          .uploadSelect-item {
            display: flex;
            align-items: center;

            img {
              width: 13px;
              height: 13px;
              margin-right: 10px;
            }

            span {
              font-size: 14px;
              color: #09aaff;
            }
          }
        }

        .newFolder {
          width: 130px;
          height: 34px;
        }

        .batchBtn {
          width: 90px;
          height: 34px;
          padding: 0;
        }
      }

      .topbar-right {
        display: flex;
        align-items: center;

        .search {
          display: flex;
          align-items: center;
          width: 360px;
          height: 34px;
          padding: 0 15px;
          background-color: #ededed;
          border-radius: 17px;

          input {
            font-size: 14px;
            flex: 1;
          }

          .i-icon {
            margin-left: 10px;
          }
        }

        .listType {
          display: flex;
          align-items: center;
          margin-left: 10px;

          .i-icon {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .list {
      height: 100%;
      overflow-y: scroll;
      padding-left: 10px;

      .total {
        font-size: 14px;
        color: #666;
        margin-top: 20px;
      }

      .operation {
        display: flex;
        align-items: center;

        ::v-deep .ivu-dropdown-rel {
          display: flex;
          align-items: center;
        }

        ::v-deep .ivu-icon {
          cursor: pointer;
        }
      }
    }
  }
</style>
<style scoped>
  .search-style >>> .ivu-input {
    border: none;
    background-color: transparent;
    outline: none;
  }

  .search-style >>> .ivu-input::placeholder {
    color: #515a6e;
  }

  .search-style >>> .ivu-input:focus {
    box-shadow: none;
  }
</style>
